import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import SiteImg1 from "../assets/img/component-site.png"


const PricesPage = () => (
  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
      }
    `}

    render={data => (
      
    <Layout type="prices">
        <SEO title="Prices" />
        

        <section className="faq-section">
          <div className="faq-container">
            <div className="uk-card uk-card-muted uk-width-1-2@m uk-align-center">
              <div>
                <h3 className="uk-text-center">FAQs</h3>
              </div>
              <div data-uk-accordion className="faq-accordion">
                
                    <li>
                      <a className="uk-accordion-title faq-question" href="#0">
                        Here's the question
                      </a>
                      <div className="uk-accordion-content faq-answer">Here's the answer</div>
                    </li>
                    <li>
                      <a className="uk-accordion-title faq-question" href="#0">
                        Here's the second question
                      </a>
                      <div className="uk-accordion-content faq-answer">Here's the answer</div>
                    </li>
                 
              </div>
            </div>
          </div>
        </section>
        

      </Layout>

    )}
  
  />
)

export default PricesPage
